import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, renderList as _renderList, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex-justified" }
const _hoisted_2 = { class: "flex-top" }
const _hoisted_3 = {
  key: 0,
  class: "enabled-title"
}
const _hoisted_4 = { class: "flex-left" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "flex-center" }
const _hoisted_7 = {
  key: 0,
  class: "flex-top"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubscriptionProductPrice = _resolveComponent("SubscriptionProductPrice")!
  const _component_VuiToggle = _resolveComponent("VuiToggle")!
  const _component_FreeFeatureBadge = _resolveComponent("FreeFeatureBadge")!
  const _component_ExpandTransition = _resolveComponent("ExpandTransition")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["feature-summary reedsy-accented", {
      active: _ctx.isProduct && _ctx.selected,
      'being-removed': _ctx.isRemovingAddon
    }])
  }, [
    _createElementVNode("rbe-feature-header", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.isPreview)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.enabledInfo), 1))
          : _createCommentVNode("", true),
        _cache[2] || (_cache[2] = _createTextVNode()),
        _createElementVNode("h2", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.summary.title) + " ", 1),
          (_ctx.product && !_ctx.isPreview)
            ? (_openBlock(), _createBlock(_component_SubscriptionProductPrice, {
                key: 0,
                class: "price",
                prices: _ctx.prices,
                product: _ctx.summary.product,
                interval: _ctx.interval
              }, null, 8, ["prices", "product", "interval"]))
            : _createCommentVNode("", true)
        ]),
        _cache[3] || (_cache[3] = _createTextVNode()),
        (_ctx.isPreview)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
              _createTextVNode("\n          Free for " + _toDisplayString(_ctx.config.stripe.trialDays) + " days, then\n          ", 1),
              _createVNode(_component_SubscriptionProductPrice, {
                class: "trial-price",
                prices: _ctx.prices,
                product: _ctx.summary.product,
                interval: _ctx.interval
              }, null, 8, ["prices", "product", "interval"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _cache[5] || (_cache[5] = _createTextVNode()),
      _createElementVNode("rbe-feature-toggle", _hoisted_6, [
        (!_ctx.isPreview)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.isProduct)
                ? (_openBlock(), _createBlock(_component_VuiToggle, {
                    key: 0,
                    ref: "subscription-toggle",
                    modelValue: _ctx.selected,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
                    class: "label-left",
                    label: _ctx.isActive && !_ctx.isRemovingAddon ? 'Subscribed' : 'Add to subscription'
                  }, null, 8, ["modelValue", "label"]))
                : (_openBlock(), _createBlock(_component_FreeFeatureBadge, { key: 1 }))
            ], 64))
          : _createCommentVNode("", true),
        _cache[4] || (_cache[4] = _createTextVNode()),
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.expanded = !_ctx.expanded))
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.expanded ? 'VuiIconUp' : 'VuiIconDown')))
        ])
      ])
    ]),
    _cache[8] || (_cache[8] = _createTextVNode()),
    _createVNode(_component_ExpandTransition, null, {
      default: _withCtx(() => [
        (_ctx.expanded)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summary.features, (feature) => {
                return (_openBlock(), _createElementBlock("li", { key: feature }, _toDisplayString(feature), 1))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _cache[9] || (_cache[9] = _createTextVNode()),
    (_ctx.isRemovingAddon)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _cache[6] || (_cache[6] = _createElementVNode("hr", { class: "removal-info-separator" }, null, -1)),
          _cache[7] || (_cache[7] = _createTextVNode()),
          _createElementVNode("rbe-removal-info", null, [
            _createElementVNode("p", null, "\n          This add-on was removed from your subscription and\n          will be disabled on " + _toDisplayString(_ctx.cancellationDate) + ".\n        ", 1)
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 2))
}