import ReedsyAnalyticsManager from '@reedsy/analytics-manager';
import {LoggerFactory} from '@reedsy/reedsy-logger-js';
import {$getDecorators} from '@reedsy/utils.inversify';
import {Store} from 'vuex';
import {VuexModule} from '@reedsy/vuex-module-decorators';
import {IClientConfig} from './config';
import {ReedsyRouter} from './router/reedsy-router';
import {IAnalyticsService} from './services/analytics/i-analytics-service';
import IApi from './services/api/i-api';
import {INavigation} from './services/navigation/i-navigation';
import {IStoreListener} from './store/helpers/store-listener/i-store-listener';
import {IModuleFactory} from './store/modules/i-module-factory';
import {IStorePluginFactory} from './store/plugins/i-store-plugin-factory';
import {ISharedStoreMapping} from './store/store-mapping';
import StoreWrapper from './store/store-wrapper';
import IModal from './store/modules/modals/i-modal';
import {IDirectiveFactory} from './directives/i-directive-factory';
import {ISubscriptionModalService} from './services/subscriptions/i-subscription-modal-service';
import {ITour} from './services/spotlight-tour/i-tour';
import {ISpotlightTour} from './services/spotlight-tour/i-spotlight-tour';
import {TourRoutes} from './services/spotlight-tour/tour-routes';
import {IWebSocketFactory} from './services/ws/i-websocket-factory';
import {Stripe} from '@stripe/stripe-js';
import {Provider} from '@reedsy/utils.types';
import {ITimingReporter} from '@reedsy/studio.shared/utils/timing/i-timing-reporter';

export type SharedBindings = {
  Analytics: IAnalyticsService;
  Api: IApi;
  Config: IClientConfig;
  Directive: IDirectiveFactory;
  LoggerFactory: LoggerFactory;
  ModalsData: {[id: string]: IModal};
  Navigation: INavigation;
  StudioAnalyticsManager: ReedsyAnalyticsManager;
  Router: ReedsyRouter;
  SharedStorePlugins: IStorePluginFactory;
  SpotlightTour: ISpotlightTour;
  Store: Store<any>;
  StoreListener: IStoreListener<ISharedStoreMapping>;
  StoreWrapper: StoreWrapper;
  StoreModule: VuexModule;
  StoreModuleFactory: IModuleFactory;
  SubscriptionModal: ISubscriptionModalService;
  TimingReporter: ITimingReporter;
  Tour: ITour;
  TourRoutes: TourRoutes;
  WebSocketFactory: IWebSocketFactory;
  StripeProvider: Provider<Stripe>;
};

export type SharedBinding = keyof SharedBindings;

export const {$inject, $multiInject} = $getDecorators<SharedBindings>();
