import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "flex-centered" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "billing-description"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "billing-description" }
const _hoisted_8 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DelayedLoadingIndicator = _resolveComponent("DelayedLoadingIndicator")!
  const _component_PeriodToggle = _resolveComponent("PeriodToggle")!
  const _component_CompactFeatureSummary = _resolveComponent("CompactFeatureSummary")!
  const _component_SubscriptionPriceBreakdown = _resolveComponent("SubscriptionPriceBreakdown")!
  const _component_SubscriptionPrice = _resolveComponent("SubscriptionPrice")!
  const _component_MultipleSaving = _resolveComponent("MultipleSaving")!

  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("rbe-loading", _hoisted_1, [
        _createVNode(_component_DelayedLoadingIndicator, {
          class: "accent-primary",
          delay: 100
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("rbe-manage-addons-panel", null, [
          _createElementVNode("rbe-addon-selector", _hoisted_3, [
            _createVNode(_component_PeriodToggle, {
              modelValue: _ctx.billAnnually,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.billAnnually) = $event))
            }, null, 8, ["modelValue"]),
            _cache[3] || (_cache[3] = _createTextVNode()),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableSummaries, (summary) => {
              return (_openBlock(), _createBlock(_component_CompactFeatureSummary, {
                key: summary.product,
                ref_for: true,
                ref: summary.product,
                modelValue: _ctx.activeAddons[summary.product],
                "onUpdate:modelValue": ($event: any) => ((_ctx.activeAddons[summary.product]) = $event),
                summary: summary,
                interval: _ctx.selectedInterval,
                prices: _ctx.prices
              }, null, 8, ["modelValue", "onUpdate:modelValue", "summary", "interval", "prices"]))
            }), 128))
          ]),
          _cache[9] || (_cache[9] = _createTextVNode()),
          (_ctx.showNewPriceBreakdown)
            ? (_openBlock(), _createElementBlock("rbe-update-subscription", _hoisted_4, [
                _createVNode(_component_SubscriptionPriceBreakdown, {
                  class: "price-info",
                  "calculated-price": _ctx.calculatedPrice,
                  "next-billing-date": _ctx.currentSubscription?.nextBilling.date
                }, null, 8, ["calculated-price", "next-billing-date"]),
                _cache[7] || (_cache[7] = _createTextVNode()),
                _createElementVNode("rbe-price-confirm", null, [
                  (_ctx.currentSubscription && _ctx.calculatedPrice)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                        _createTextVNode("\n            On " + _toDisplayString(_ctx.currentSubscription.nextBilling.date) + " your subscription will change from\n            ", 1),
                        _createVNode(_component_SubscriptionPrice, {
                          price: _ctx.currentSubscription.nextBilling.price,
                          currency: _ctx.currentSubscription.currency,
                          interval: _ctx.currentSubscription.interval
                        }, null, 8, ["price", "currency", "interval"]),
                        _cache[4] || (_cache[4] = _createTextVNode(" to ")),
                        _createVNode(_component_SubscriptionPrice, {
                          price: _ctx.calculatedPrice.total,
                          currency: _ctx.calculatedPrice.currency,
                          interval: _ctx.calculatedPrice.interval
                        }, null, 8, ["price", "currency", "interval"]),
                        _cache[5] || (_cache[5] = _createTextVNode(".\n            You can continue to use existing add-ons until that date.\n          "))
                      ]))
                    : _createCommentVNode("", true),
                  _cache[6] || (_cache[6] = _createTextVNode()),
                  _createElementVNode("button", {
                    ref: "updateSubscriptionButton",
                    type: "button",
                    class: "button reedsy-accented",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.continueToPayment && _ctx.continueToPayment(...args)))
                  }, _toDisplayString(_ctx.currentSubscription ? 'Update subscription' : 'Continue to payment'), 513)
                ])
              ]))
            : (_ctx.cancelling)
              ? (_openBlock(), _createElementBlock("rbe-update-subscription", _hoisted_6, [
                  _createElementVNode("rbe-price-confirm", null, [
                    _createElementVNode("span", _hoisted_7, "\n            You can continue to use existing add-ons until " + _toDisplayString(_ctx.currentSubscription.nextBilling.date) + ".\n          ", 1),
                    _cache[8] || (_cache[8] = _createTextVNode()),
                    _createElementVNode("button", {
                      ref: "cancelSubscriptionButton",
                      type: "button",
                      class: "button reedsy-accented danger",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openConfirmCancelModal && _ctx.openConfirmCancelModal(...args)))
                    }, "\n            Cancel subscription\n          ", 512)
                  ])
                ]))
              : _createCommentVNode("", true)
        ]),
        _cache[11] || (_cache[11] = _createTextVNode()),
        (_ctx.availableSummaries.length > 1)
          ? (_openBlock(), _createElementBlock("rbe-additional-savings", _hoisted_8, [
              _createVNode(_component_MultipleSaving),
              _cache[10] || (_cache[10] = _createTextVNode("\n      when you purchase multiple add-ons\n    "))
            ]))
          : _createCommentVNode("", true)
      ]))
}