
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {PropType} from 'vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {IBillingInterval} from '@reedsy/schemas.editor-collections';
import {SubscriptionProduct} from '@reedsy/utils.subscription';
import SubscriptionPrice from './subscription-price.vue';
import {choosePrice} from '@reedsy/studio.shared/services/stripe/choose-price';
import {IPriceOptionsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-options-response';

@Component({
  components: {
    SubscriptionPrice,
  },
})
export default class SubscriptionProductPrice extends ClientSharedVue {
  @Prop({type: String as PropType<SubscriptionProduct>})
  public product: SubscriptionProduct;

  @Prop({type: String as PropType<IBillingInterval>})
  public interval: IBillingInterval;

  @Prop({type: Object as PropType<IPriceOptionsResponse>})
  public prices: IPriceOptionsResponse;

  public get showDiscountedPrice(): boolean {
    return this.interval === 'year';
  }

  public get beforeDiscountMonthlyPrice(): number {
    return this.offeringPrice('month');
  }

  public get monthlyPrice(): number {
    if (this.interval === 'month') return this.offeringPrice('month');
    const yearlyPrice = this.offeringPrice('year');
    return yearlyPrice / 12;
  }

  public get displayedInterval(): IBillingInterval {
    return 'month';
  }

  public offeringPrice(interval: IBillingInterval): number {
    if (!this.prices) return;

    const {userCurrency, offerings} = this.prices;
    return choosePrice(userCurrency, offerings[this.product][interval]).price;
  }
}
