import {oneLine} from 'common-tags';
import {titleByProduct} from '@reedsy/studio.shared/components/modals/components/subscription/features/feature-summaries';

export const PREMIUM_FEATURES_DESCRIPTIONS = Object.freeze({
  checkIn: oneLine`
    Check-ins are a great way to stay accountable and build a writing routine.
    Think of them as a short message posted to your timeline each day recording what you worked on.
  `,
  goals: oneLine`
    Build a writing routine and stay accountable with custom goals.
    Create daily, weekly, and monthly word count targets and get notified if you fall behind schedule.
  `,
  attributes: oneLine`
    Attributes are a premium feature. They allow you to structure your assets, characters,
    and locations and create relationships between them.
  `,
  documentHistory: oneLine`
    After six months of inactivity, we’ll clear your history and keep only your latest draft.
    For unlimited access to your revisions, upgrade to ${titleByProduct['writing']}.
  `,
} as const);
