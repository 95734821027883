<template>
  <component
    :is="isProduct ? 'button' : 'div'"
    class="feature-summary reedsy-accented"
    :class="{
      product: isProduct,
      'vui-tab-option': isProduct, // Reuse some styling but without the full component
      active: isProduct && isActive,
    }"
    @click="toggleSelected"
  >
    <h2 class="flex-justified">
      {{ summary.title }}

      <SubscriptionProductPrice
        v-if="isProduct && !isPreview"
        :prices="prices"
        :product="summary.product"
        :interval="interval"
        :class="{
          'reedsy-accented': selected,
        }"
      />
      <FreeFeatureBadge v-else-if="!isPreview" />
    </h2>

    <ul>
      <li
        v-for="feature in summary.features"
        :key="feature"
      >
        {{ feature }}
      </li>
    </ul>

    <span
      v-if="isPreview && prices"
      class="trial-price-info"
    >
      Free for {{ config.stripe.trialDays }} days, then
      <SubscriptionProductPrice
        class="price"
        :prices="prices"
        :product="summary.product"
        :interval="interval"
      />
    </span>

    <button
      v-else-if="isProduct"
      type="button"
      class="button button-full reedsy-accented product-button button-sm"
      :class="{
        'bordered neutral': !selected,
      }"
    >
      {{ selected ? 'Add-on selected' : 'Select add-on' }}
      <VuiToggle
        :model-value="selected"
        class="borderless"
      />
    </button>

    <VuiIconTick
      v-if="isProduct"
      class="icon-tick reedsy-accented plain"
    />
  </component>
</template>

<script lang="ts">
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {IProductSummary} from './product-summary.interface';
import {PropType} from 'vue';
import {config} from '@reedsy/studio.shared/config';
import {IPriceOptionsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-options-response';
import {IBillingInterval} from '@reedsy/schemas.editor-collections';
import SubscriptionProductPrice from '@reedsy/studio.shared/components/subscriptions/subscription-product-price.vue';
import FreeFeatureBadge from './free-feature-badge.vue';

@Component({components: {
  SubscriptionProductPrice,
  FreeFeatureBadge,
}})
export default class FeatureSummary extends ClientSharedVue {
  @Model({type: Boolean})
  public selected: boolean;

  @Prop({type: Boolean, default: false})
  public isPreview: boolean;

  @Prop({type: Object as PropType<IProductSummary>, required: true})
  public summary: IProductSummary;

  @Prop({type: Object as PropType<IPriceOptionsResponse>})
  public prices: IPriceOptionsResponse;

  @Prop({type: String as PropType<IBillingInterval>})
  public interval: IBillingInterval;

  public readonly config = config;

  public get isProduct(): boolean {
    return !!this.summary.product;
  }

  public get isActive(): boolean {
    return this.isPreview || this.selected;
  }

  public toggleSelected(): void {
    if (!this.isProduct) return;
    this.selected = !this.selected;
  }
}
</script>

<style lang="scss" scoped>
.feature-summary {
  --vui-tabOption-indicator-size: 3.5rem;
  --vui-tabOption-icon-size: 0.75rem;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  border-radius: $border-radius-lg;
  padding: $space-base;
  margin: 0;
  gap: 0;
  background-color: var(--reedsy-plain);
  box-sizing: border-box;

  &.product {
    border: $border-width-lg solid var(--reedsy-neutral);

    &.active,
    &:hover {
      border: $border-width-lg solid var(--local-color-default);
    }

    &:hover {
      --local-color-default: var(--local-color-emphasis);
    }
  }

  &:not(.product),
  &.active {
    box-shadow: $box-shadow-base;
  }

  ul {
    padding: 0;
    flex: 1 0 auto;
    width: 100%;

    li {
      @include font-family($controls);

      margin: $space-sm 0;
    }
  }

  h2 {
    width: 100%;

    :deep(.skeleton-loader) {
      width: 6.5rem;
    }

    :deep(.interval) {
      @include font-family($controls, normal);

      font-size: $font-size-base;
    }
  }

  .trial-price-info {
    @include font-family($controls, normal);

    margin: $space-sm 0;

    .price {
      text-decoration: underline;
    }
  }

  .product-button {
    display: flex;
    justify-content: space-between;

    .vui-toggle.borderless {
      :deep(.toggle) {
        --local-color-default: var(--studio-subscriptions-featureSummary-toggleHandle-color);

        border-color: transparent;
        background-color: var(--studio-subscriptions-featureSummary-toggle-backgroundColor);
      }

      &.checked :deep(.toggle) {
        background-color: var(--studio-subscriptions-featureSummary-toggle-backgroundColor-selected);
      }
    }
  }

  &:hover .product-button .vui-toggle.borderless {
    &.checked :deep(.toggle) {
      background-color: var(--studio-subscriptions-featureSummary-toggle-backgroundColor-selected-hover);
    }
  }
}
</style>
