<template>
  <SkeletonLoader
    v-if="loading"
    class="price-loader"
  />
  <span
    v-else
    class="price"
  >
    <span
      v-if="isDiscounted"
      class="pre-discount-amount"
    >{{ beforeDiscountAmount }}</span>
    <span class="after-discount-amount">
      <span class="amount">{{ amount }}</span><span class="interval">{{ intervalDisplay }}</span>
    </span>
  </span>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {PropType} from 'vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {IBillingInterval, SupportedCurrency} from '@reedsy/schemas.editor-collections';
import SkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/skeleton-loader.vue';
import {currencyAmount} from '@reedsy/studio.shared/filters/currency-amount';

const INTERVAL_SEPARATOR: string = '/';
const INTERVAL_DISPLAY: Record<IBillingInterval, string> = {
  month: 'mo',
  year: 'yr',
};

@Component({
  components: {
    SkeletonLoader,
  },
})
export default class SubscriptionPrice extends ClientSharedVue {
  @Prop({type: Number, default: null})
  public beforeDiscountPrice: number;

  @Prop({type: Number})
  public price: number;

  @Prop({type: String as PropType<SupportedCurrency>})
  public currency: SupportedCurrency;

  @Prop({type: String as PropType<IBillingInterval>})
  public interval: IBillingInterval;

  public get isDiscounted(): boolean {
    return this.beforeDiscountPrice !== null;
  }

  public get loading(): boolean {
    return !(this.price !== undefined && this.currency && this.interval);
  }

  public get amount(): string {
    return currencyAmount(this.price, this.currency);
  }

  public get beforeDiscountAmount(): string {
    return currencyAmount(this.beforeDiscountPrice, this.currency);
  }

  public get intervalDisplay(): string {
    return INTERVAL_SEPARATOR + INTERVAL_DISPLAY[this.interval];
  }
}
</script>

<style lang="scss" scoped>
.price-loader {
  height: 1em;
}

.price {
  display: inline-flex;
  flex-direction: row;
  gap: $space-xs;
  align-items: flex-end;

  .pre-discount-amount {
    color: var(--reedsy-neutral-highContrast);
    text-decoration: line-through;
  }

  &.reedsy-accented .after-discount-amount {
    color: var(--local-color-default);
  }
}
</style>
