import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "price"
}
const _hoisted_2 = {
  key: 0,
  class: "pre-discount-amount"
}
const _hoisted_3 = { class: "after-discount-amount" }
const _hoisted_4 = { class: "amount" }
const _hoisted_5 = { class: "interval" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkeletonLoader = _resolveComponent("SkeletonLoader")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_SkeletonLoader, {
        key: 0,
        class: "price-loader"
      }))
    : (_openBlock(), _createElementBlock("span", _hoisted_1, [
        (_ctx.isDiscounted)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.beforeDiscountAmount), 1))
          : _createCommentVNode("", true),
        _cache[0] || (_cache[0] = _createTextVNode()),
        _createElementVNode("span", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.amount), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.intervalDisplay), 1)
        ])
      ]))
}