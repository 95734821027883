import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "no-goals"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "more-settings" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PremiumInfo = _resolveComponent("PremiumInfo")!
  const _component_PanelBodyTitle = _resolveComponent("PanelBodyTitle")!
  const _component_GoalToggle = _resolveComponent("GoalToggle")!
  const _component_ItemsBox = _resolveComponent("ItemsBox")!
  const _component_SkeletonLoader = _resolveComponent("SkeletonLoader")!
  const _component_VuiToggle = _resolveComponent("VuiToggle")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, {
    title: "Notification",
    class: "book-notifications-settings-panel"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PanelBodyTitle, { class: "no-top-space" }, {
        "premium-info": _withCtx(() => [
          _createVNode(_component_PremiumInfo, {
            "paid-feature": _ctx.paidFeatures.goals
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.premiumDescriptions.goals), 1)
            ]),
            _: 1
          }, 8, ["paid-feature"])
        ]),
        default: _withCtx(() => [
          _cache[1] || (_cache[1] = _createTextVNode("\n      Writing goals\n\n      "))
        ]),
        _: 1
      }),
      _cache[9] || (_cache[9] = _createTextVNode()),
      (_ctx.hasGoals)
        ? (_openBlock(), _createBlock(_component_ItemsBox, { key: 0 }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bookUserGoals, (goal) => {
                return (_openBlock(), _createBlock(_component_GoalToggle, {
                  key: goal._id,
                  goal: goal
                }, null, 8, ["goal"]))
              }), 128))
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock("p", _hoisted_1, "\n      No user goals set for the book\n    ")),
      _cache[10] || (_cache[10] = _createTextVNode()),
      (_ctx.isSubscriptionsFeatureEnabled)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _createVNode(_component_PanelBodyTitle, { ref: "check-in-title" }, {
              "premium-info": _withCtx(() => [
                _createVNode(_component_PremiumInfo, {
                  "paid-feature": _ctx.paidFeatures.checkIns
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.premiumDescriptions.checkIn), 1)
                  ]),
                  _: 1
                }, 8, ["paid-feature"])
              ]),
              default: _withCtx(() => [
                _cache[2] || (_cache[2] = _createTextVNode("\n        Check-in\n        "))
              ]),
              _: 1
            }, 512),
            _cache[5] || (_cache[5] = _createTextVNode()),
            _createVNode(_component_ItemsBox, null, {
              default: _withCtx(() => [
                (_ctx.isLoadingUserBookSettings)
                  ? (_openBlock(), _createElementBlock("rbe-toggle-loader", _hoisted_2, [
                      _cache[3] || (_cache[3] = _createElementVNode("p", null, "Reminder to check-in after writing", -1)),
                      _cache[4] || (_cache[4] = _createTextVNode()),
                      _createVNode(_component_SkeletonLoader, { class: "loader" })
                    ]))
                  : (_openBlock(), _createBlock(_component_VuiToggle, {
                      key: 1,
                      ref: "check-in-toggler",
                      modelValue: _ctx.checkInNotificationEnabled,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkInNotificationEnabled) = $event)),
                      label: "Reminder to check-in after writing",
                      class: "label-left spread",
                      disabled: !_ctx.subscription.hasFeature(_ctx.paidFeatures.checkIns) || null
                    }, null, 8, ["modelValue", "disabled"]))
              ]),
              _: 1
            })
          ], 64))
        : _createCommentVNode("", true),
      _cache[11] || (_cache[11] = _createTextVNode()),
      _createElementVNode("p", _hoisted_3, [
        _cache[7] || (_cache[7] = _createTextVNode("\n      You can find more notification preferences in the\n      \n      \n      ")),
        _createVNode(_component_RouterLink, { to: _ctx.userNotificationsSettingsRoute }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("user settings page")
          ])),
          _: 1
        }, 8, ["to"]),
        _cache[8] || (_cache[8] = _createTextVNode(".\n    "))
      ])
    ]),
    _: 1
  }))
}