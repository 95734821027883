<template>
  <rbe-loading v-if="loading">
    <DelayedLoadingIndicator
      class="accent-primary"
      :delay="100"
    />
  </rbe-loading>
  <div v-else>
    <rbe-manage-addons-panel>
      <rbe-addon-selector class="flex-centered">
        <PeriodToggle v-model="billAnnually" />
        <CompactFeatureSummary
          v-for="summary in availableSummaries"
          :key="summary.product"
          :ref="summary.product"
          v-model="activeAddons[summary.product]"
          :summary="summary"
          :interval="selectedInterval"
          :prices="prices"
        />
      </rbe-addon-selector>

      <rbe-update-subscription v-if="showNewPriceBreakdown">
        <SubscriptionPriceBreakdown
          class="price-info"
          :calculated-price="calculatedPrice"
          :next-billing-date="currentSubscription?.nextBilling.date"
        />

        <rbe-price-confirm>
          <span
            v-if="currentSubscription && calculatedPrice"
            class="billing-description"
          >
            On {{ currentSubscription.nextBilling.date }} your subscription will change from
            <SubscriptionPrice
              :price="currentSubscription.nextBilling.price"
              :currency="currentSubscription.currency"
              :interval="currentSubscription.interval"
            /> to <SubscriptionPrice
              :price="calculatedPrice.total"
              :currency="calculatedPrice.currency"
              :interval="calculatedPrice.interval"
            />.
            You can continue to use existing add-ons until that date.
          </span>

          <!-- TODO: [subscriptions]: add update subscription functionality -->
          <button
            ref="updateSubscriptionButton"
            type="button"
            class="button reedsy-accented"
            @click="continueToPayment"
          >
            {{ currentSubscription ? 'Update subscription' : 'Continue to payment' }}
          </button>
        </rbe-price-confirm>
      </rbe-update-subscription>
      <rbe-update-subscription v-else-if="cancelling">
        <rbe-price-confirm>
          <span class="billing-description">
            You can continue to use existing add-ons until {{ currentSubscription.nextBilling.date }}.
          </span>

          <button
            ref="cancelSubscriptionButton"
            type="button"
            class="button reedsy-accented danger"
            @click="openConfirmCancelModal"
          >
            Cancel subscription
          </button>
        </rbe-price-confirm>
      </rbe-update-subscription>
    </rbe-manage-addons-panel>

    <rbe-additional-savings v-if="availableSummaries.length > 1">
      <MultipleSaving />
      when you purchase multiple add-ons
    </rbe-additional-savings>
  </div>
</template>

<script lang="ts">
import {Component, Watch} from '@reedsy/studio.shared/utils/vue/decorators';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import PeriodToggle from '@reedsy/studio.shared/components/modals/components/subscription/features/period-toggle.vue';
import CompactFeatureSummary from '@reedsy/studio.shared/components/modals/components/subscription/features/compact-feature-summary.vue';
import {FEATURE_SUMMARIES} from '@reedsy/studio.shared/components/modals/components/subscription/features/feature-summaries';
import MultipleSaving from '@reedsy/studio.shared/components/modals/components/subscription/multiple-saving.vue';
import {SubscriptionProduct} from '@reedsy/utils.subscription';
import {deepEqual} from '@reedsy/utils.deep-equal';
import {objectKeys} from '@reedsy/utils.object';
import {ICurrentSubscription} from '@reedsy/studio.shared/store/modules/subscription/current-subscription.interface';
import {ICalculatePriceResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-calculate-price-response';
import {IBillingInterval} from '@reedsy/schemas.editor-collections';
import DelayedLoadingIndicator from '@reedsy/studio.shared/components/loader/delayed-loading-indicator.vue';
import SubscriptionPriceBreakdown from '@reedsy/studio.shared/components/subscriptions/subscription-price-breakdown.vue';
import SubscriptionPrice from '@reedsy/studio.shared/components/subscriptions/subscription-price.vue';
import {ISubscriptionModalService} from '@reedsy/studio.shared/services/subscriptions/i-subscription-modal-service';
import {IPriceOptionsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-options-response';
import {DEFAULT_BILLING_INTERVAL} from '@reedsy/studio.shared/components/modals/components/subscription/features/default-billing-interval';

@Component({
  components: {
    PeriodToggle,
    CompactFeatureSummary,
    MultipleSaving,
    DelayedLoadingIndicator,
    SubscriptionPriceBreakdown,
    SubscriptionPrice,
  },
})
export default class ManageAddonsPanel extends ClientSharedVue {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @$lazyInject('SubscriptionModal')
  public $subscriptionModal: ISubscriptionModalService;

  public readonly availableSummaries = FEATURE_SUMMARIES.filter((summary) => summary.product);

  public loading = true;
  public selectedInterval: IBillingInterval = DEFAULT_BILLING_INTERVAL;
  public activeAddons: Partial<Record<SubscriptionProduct, boolean>> = {};
  public calculatedPrice: ICalculatePriceResponse = null;
  public prices: IPriceOptionsResponse = null;

  public get currentSubscription(): ICurrentSubscription {
    return this.$subscription.currentSubscriptionInfo;
  }

  public get selectedAddons(): Set<SubscriptionProduct> {
    return new Set(objectKeys(this.activeAddons).filter((product) => this.activeAddons[product]));
  }

  public get nextPeriodSelectedAddons(): Set<SubscriptionProduct> {
    return new Set(this.currentSubscription?.nextBilling.products);
  }

  public get billAnnually(): boolean {
    return this.selectedInterval === 'year';
  }

  public set billAnnually(billAnnually: boolean) {
    this.selectedInterval = billAnnually ? 'year' : 'month';
  }

  public get activeFeaturesHaveChanged(): boolean {
    return !deepEqual(this.nextPeriodSelectedAddons, this.selectedAddons);
  }

  public get billingPeriodHasChanged(): boolean {
    const previousInterval = this.currentSubscription ? this.currentSubscription.interval : DEFAULT_BILLING_INTERVAL;
    return this.selectedInterval !== previousInterval;
  }

  public get showNewPriceBreakdown(): boolean {
    if (!this.selectedAddons.size) return false;
    return this.billingPeriodHasChanged || this.activeFeaturesHaveChanged;
  }

  public get cancelling(): boolean {
    return this.currentSubscription && !this.selectedAddons.size;
  }

  public async created(): Promise<void> {
    [, this.prices] = await Promise.all([
      this.$subscription.refreshCurrentSubscriptionInfo(),
      this.$subscription.fetchPrice(),
    ]);

    this.loading = false;

    if (!this.currentSubscription) return;
    this.selectedInterval = this.currentSubscription.interval;
    this.currentSubscription.products.forEach((key: SubscriptionProduct) => {
      this.activeAddons[key] = true;
    });
  }

  public continueToPayment(): void {
    this.$subscriptionModal.openPayment({
      price: this.calculatedPrice,
    });
  }

  @Watch('selectedInterval')
  @Watch('activeAddons', {deep: true})
  public async subscriptionUpdated(): Promise<void> {
    this.calculatedPrice = null;
    if (!this.selectedAddons.size) return;

    const interval = this.selectedInterval;
    const products = [...this.selectedAddons];
    this.calculatedPrice = await this.$subscription.calculateSubscriptionPrice({
      interval,
      products,
    });
  }

  public openConfirmCancelModal(): void {
    this.$subscriptionModal.openCancelConfirmation();
  }
}
</script>

<style lang="scss" scoped>
rbe-manage-addons-panel {
  border: $border-lg;
  border-radius: $border-radius-lg;
  background-color: var(--reedsy-neutral-muted);
  overflow: hidden;

  rbe-addon-selector {
    padding: $space-base;
    gap: $space-base;
  }

  rbe-update-subscription {
    background-color: var(--reedsy-plain);

    .price-info {
      padding: $space-base;
      border-top: $border-lg;
      border-bottom: $border;
    }

    rbe-price-confirm {
      display: flex;
      flex-direction: row;
      gap: $space-base;
      padding: $space-base;
      align-items: flex-start;

      @include screen-less-than(sm) {
        flex-direction: column-reverse;

        button {
          width: 100%;
        }
      }

      button {
        margin-left: auto;
        white-space: nowrap;
      }

      .billing-description {
        align-self: center;
      }
    }
  }
}

rbe-additional-savings {
  text-align: center;
  margin-top: $space-base;
}

rbe-loading {
  padding: $space-base;
  align-self: center;
}

.products-changing-info-panel {
  width: 100%;

  ul {
    li {
      @include font-family($controls, bold);
    }
  }
}
</style>
