import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubscriptionPrice = _resolveComponent("SubscriptionPrice")!

  return (_openBlock(), _createBlock(_component_SubscriptionPrice, {
    "before-discount-price": _ctx.showDiscountedPrice ? _ctx.beforeDiscountMonthlyPrice : null,
    price: _ctx.monthlyPrice,
    currency: _ctx.prices?.userCurrency,
    interval: _ctx.displayedInterval
  }, null, 8, ["before-discount-price", "price", "currency", "interval"]))
}